<template>
  <div>
    <v-dialog v-model="dialog1" max-width="80%" persistent>
      <v-card class="pa-4">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <span>status ปัจจุบัน</span>
            <br />
            <span>{{ status.status }}</span>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              class="mt-4"
              v-model="selectedstatus"
              :items="[
                'Waiting for verification',
                'Request verified',
                'Approved',
                'Pending',
                'Declined',
              ]"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            v-if="
              selectedstatus == 'Pending' ||
              status.status == 'Pending' ||
              selectedstatus == 'Declined' ||
              status.status == 'Declined'
            "
          >
            <span>Note:</span>
            <v-textarea
              v-model="admin_status_note"
              outlined
              dense
              rows="3"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-btn @click="close()" class="mr-2">cancel</v-btn>
            <v-btn
              @click="changeStatus(status.id, selectedstatus)"
              color="primary"
              >save</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
export default {
  props: {
    dialog1: {
      type: Boolean,
      default: false,
    },

    status: Object,
    EngLang: Boolean,
    URLenpoint: String,
  },
  data() {
    return {
      // dialog: false,
      selectedstatus: "",
      admin_status_note: "",
    };
  },
  watch: {
    "status.status"(val) {
      this.selectedstatus = val;
    },
    "status.admin_status_note"(val) {
      this.admin_status_note = val;
    },
  },
  methods: {
    async changeStatus(id, selectedstatus) {
      console.log("iremssss", id, selectedstatus);
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const data = {
        status: selectedstatus,
        admin_status_note: this.admin_status_note,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/${this.URLenpoint}/${id}`,
        data,
        auth
      );
      console.log("response status", response);
      this.$emit("closeDialog");
    },
    close() {
      this.$emit("closeDialog");
    },
  },
};
</script>