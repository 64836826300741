var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"80%","persistent":""},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("status ปัจจุบัน")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.status.status))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":[
              'Waiting for verification',
              'Request verified',
              'Approved',
              'Pending',
              'Declined' ],"outlined":"","dense":""},model:{value:(_vm.selectedstatus),callback:function ($$v) {_vm.selectedstatus=$$v},expression:"selectedstatus"}})],1),(
            _vm.selectedstatus == 'Pending' ||
            _vm.status.status == 'Pending' ||
            _vm.selectedstatus == 'Declined' ||
            _vm.status.status == 'Declined'
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Note:")]),_c('v-textarea',{attrs:{"outlined":"","dense":"","rows":"3"},model:{value:(_vm.admin_status_note),callback:function ($$v) {_vm.admin_status_note=$$v},expression:"admin_status_note"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",on:{"click":function($event){return _vm.close()}}},[_vm._v("cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeStatus(_vm.status.id, _vm.selectedstatus)}}},[_vm._v("save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }